import { Container } from '@mui/system'
import React, { Component } from 'react'
import Header from '../common/header'

class Biography extends Component {
    render() {
        return (
            <div style={{ minHeight: '100VH' }}>
                <Header>
                </Header>
                <div >
                    <h1 style={{ color: '#c8cac0', fontSize: '2rem' }} className="titles">Pozdrav, ja sam Du&scaron;an Aragonski</h1>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%'}}>
                        <Container style={{zIndex:2, right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'justify'}}>Rođen sam i živim u Kragujevcu sa ženom i ćerkom. Poslednjih pet godina vodim svoju ordinaciju u kojoj sam radio sa preko 500 ljudi na promeni njihove ishrane i navika. U slobodno vreme vodim <i>Dungeons and Dragons</i> i igram indi igrice za koje je malo ljudi čulo.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'justify'}}>Diplomirao sam Biohemiju na Univerzitetu u Novom Sadu 2016. Tri godine kasnije krenuo sam profesionalno da se bavim ishranom koja mi je oduvek bila fascinantna. 2021. završio sam master Ishrane i suplementacije na Medicinskom fakultetu tako i formalno postao stručnjak za ishranu.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'justify'}}>Jedinstvenost mog pristupa nutriciji je odsustvo "Tvrdih restrikcija", odnosno zabrana i moranja. Nakon godina rada sa ljudima i izučavanja oblasti, našao sam dobar i održiv pristup poboljšanju ishrane. “Meke restrikcije” ukazuju na put ka ostvarenju ciljeva, bez toga da ljudi sada postanu neki drugi ljudi. Umesto “Ne smeš da piješ sokove” nudim “Kupi limenku kada ti se pije i završi sa tim”. Umestо “Moraš da jedeš ovo za doručak” nudim “Šta ti od ovih 20 stvari deluje interesantno?”.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'justify'}}>Ishrana nije samo nešto što svakodnevno moramo da radimo kako bi ostali živi. Ona je najjača i najteže promenjiva navika. Iz ovog razloga, za promenu ishrane nije dovoljno znati šta treba i šta ne treba jesti, već je neophodna visoka i postojana motivaciju za promenu. Zato, moji posao nije samo da edukujem, već i da pomognem ljudima da vide gde su, šta je problem i gde žele da budu.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'justify'}}>Kao predavač, u saradnji sa više organizacija, održao sam brojna predavanja na temu ishrane i navika, kojima je prisustvovalo preko 3.000 ljudi. Širenje znanja, a posebno edukacija mlađe populacije predstavlja krunu moga rada i nešto na šta sam izuzetno ponosan.</p>
                            <p style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'justify'}}>Tvoje prisustvo ovde znači da si već napravio/la prvi korak! Želiš li da napraviš i drugi? <a style={{ color: '#c8cac0', textShadow: '1px 1px 1px #214751', textAlign:'center'}} href="/contact">Kontaktiraj me</a> da popričamo o tvojim ciljevima i putu da ih postigneš.</p>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default Biography