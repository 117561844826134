import React from 'react'
import { useNavigate } from 'react-router-dom';



const ScienceBlock = (props) => {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', maxWidth: '320px', maxHeight: '450px', paddingRight: '3vW', paddingBottom: '3vH',cursor: 'pointer' }} onClick={() => navigate(`/science/blog_post/${props.identity}`)}>
            <img alt=''style={{ height: '33%', borderTopLeftRadius: '25px', borderTopRightRadius: '25px', border: '3px solid #ccc'}}  src={props.image} />
            <div style={{ height: '67%', borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px', backgroundColor: '#c8cac0', maxHeight: '250px', overflow: 'hidden', paddingInline: '10px', boxShadow: '0px 0px 14px #0000000D', }}>
                <h2 style={{ color: 'black',fontSize:'1.5rem',}} className="titles"><b>{props.title}</b></h2>
                <p style={{ color: 'black', display:'-webkit-box', textOverflow:'ellipsis', WebkitLineClamp:'6', WebkitBoxOrient:'vertical', overflow:'hidden', textAlign:'center'}} className="text">{props.text}</p>
            </div>
        </div>
    )
}

export default ScienceBlock