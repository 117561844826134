import React from 'react'
import FirstImage from '../blog_images/Test.jpg'

const BlogPost8 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>
            <div>
                <img src= {FirstImage} alt="Description" className="blogimage"/>
            </div>
            <p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost8