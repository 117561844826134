import { Container } from '@mui/system'
import React from 'react';
import Header from '../common/header'
import Blogs from './blog_texts.json'

const ScienceDetail = () =>  {
    const url = window.location.pathname
    window.scrollTo(0, 0);
    const BlogPost = url.split("/").pop()
    const BlogTextComponent =   require(`./blog_html/blog_${BlogPost}.js`).default
            return (
                <div style={{ minHeight: '100VH', }}>
                    <div style={{ backgroundSize:'cover' }}>
                        <Header>
                        </Header>
                        <h1 style={{color: '#c8cac0', fontSize: '3.2rem', textShadow: '3px 3px 3px #214751', marginBottom:'3VH'}}>{Blogs.articles[BlogPost].title}</h1>
                    </div>
                    <div  style={{minWidth:'100%', backgroundColor: '#c8cac0'}}>
                    <Container style={{display: 'flex', flexDirection: 'row'}}>
                        <BlogTextComponent ></BlogTextComponent>  
                    </Container>

                    </div>
                </div>
            )
}

export default ScienceDetail