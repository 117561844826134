import React from 'react'
import FirstImage from '../blog_images/blog_post_large_djovak.jpg'

const BlogPost0 = () => {
    return (
        <div style={{ width: '100VW', overflowWrap: 'anywhere' }}>
            <div>
                <img src= {FirstImage} alt="Description" className="blogimage"/>
            </div>
            <p>Novak Đoković drži rekord u broju nedelja na prvom mestu ATP liste, ukupno 325 nedelje. Na prvom mestu, na kraju godine na&scaron;ao se &scaron;est puta i rekord deli sa svojim idolom Samprasom. O neverovatnim rezultatima srpskog tenisera govori i činjenica da je jedini igrač u istoriji koji je dva puta osvojio svaki od četiri grend slem turnira. U ostatku teksta biće vi&scaron;e reči o Đokovićevoj ishrani, koja je nedvosmisleno jedan od značajnih razloga ovakvih uspeha i kvaliteta njegove igre.</p>
            <p>Ishrana svetskog &scaron;ampiona sigurno nije za svakoga, ali njegove disciplinovanosti i odnosa prema hrani može dosta naučiti. Odabir namirnica, raspored obroka i izbegavanje određenih supstanci u hrani, dugo je predstavljao određenu kontroverzu i predmet diskusije, po&scaron;to se dosta razlikuje od uobčajenog načina ishrane u Srbiji. Poku&scaron;aću da objasnim za&scaron;to je ovakav režim pogodan za najvećeg srpskog sportistu, ali i za&scaron;to je za većinu ljudi gotovo neizvodljiv.</p>
            <h3>Kako je sve počelo?</h3>
            <p><a href="https://www.gq.com.au/fitness/health-nutrition/heres-what-novak-djokovic-eats-in-a-day/image-gallery/7b423e086cfa05addc3463faba61224b">2010.</a> Đoković je igrao meč protiv Jo-Wilfried Tsonga u četvrt finalu Australijskog otvorenog prvenstva. Tokom ovog meča je poveo 2-1 u prva tri seta, kada je tokom pauze za toalet osetio probleme sa disanjem i malaksalost, čemu je usledila mučnina i povraćanje. Nakon povratka na teren, uspeo je da osvoji samo četiri gema od preostala dva seta i izgubio meč.</p>
            <p>Nutricionista iz srbije, <a href="https://www.drigor.org/">Dr Igor Četojević</a> koji je gledao ovaj meč, zaključio je da su ovi problemi izazvani disbalansom u digestivnom sistemu i da mogu biti neutralisani ispravnom ishranom. Nakon meča, Đoković i Dr Četojević su se sreli na konsultacijama tokom kojih je doktor ustanovio netoleranciju na gluten. Ovaj zaključak je kasnije i potvrdio biohemijskim analizama, pri čemu je ustanovio i jaku netoleranciju na mlečne proizvode i slabu na paradajz.</p>
            <p>Novak je nakon ovoga iz ishrane izbacio gluten, mlečne proizvode i paradajz i osetio ogromnu promenu. Ovo nikako ne znači da svi ljudi treba da iz ishrane izbace ove namirnice, ali ukazuje na bitnost biohemijskih kontrola i doprinos stručnjaka za postizanje najboljih rezultata.</p>
            <h3>Uticaj na igru</h3>
            <p>Sve &scaron;to radi, sve &scaron;to jede i pije prati striktan plan koji za cilj ima održavanje Novakove vrhunske forme. 2015. Novak je za <a href="https://www.independent.co.uk/sport/tennis/novak-djokovic-diet-how-i-became-best-a6734821.html">Independent </a> izjavio da je za njegov uspeh odgovorno mnogo faktora, od bezglutenske ishrane, preko treninga i pažljivog planiranja, do srećnog porodičnog života. Dalje govori da je najbolje rezultate postigao vodeći se holističkim pristupom životu i tenisu i da ne postoji jedna stvar, tajna ili eliksir koji mu je pomogao da bude to &scaron;to je danas. On smatra da su za uspeh odgovorni svi aspekti njegovog života zajedno i da je neophodno slu&scaron;ati svoje telo. Vodeći se ovom filozofijom, Đoković je od talentovanog igrača sa puno fizičkih problema postao sportista koji je u stanju da fizički i psihički podnese gotovo sve.</p>
            <p>Prva promena koju je napravio, izbacivanje glutena iz ishrane transformisala ga je u potpuno drugog igrača. Ova drastilčna promena u ishrani, odrazila se velikim skokom performansi, pri čemu je Novak dobio vi&scaron;e energije, bolju koncentraciju i veću izdržljivost.</p>
            <p>Najveća promena se može primetiti u njegovom čvrstom mentalnom stavu i odlučnosti. Dok se na početku karijere oslanjao na svoju fizičku spremnost, izdržljivost i fleksibilnost kako bi do&scaron;ao do pobede, sa promenom ishrane tome je dodao mogućnost brzog dono&scaron;enja odluka i mentalnu sabilnost čak i u najtežim situacijama. Njegova odučnost se mogla videti i u njegovom poslednjem meču protiv Tsitsipasa, na otvorenom prvenstvu Francuske, kada je nakon izgubljena dva seta, uspeo da triumfuje rezultatom 3-2 nakon vi&scaron;e od četiri sata igre.</p>
            <p><a href="https://thetennisbros.com/tennis-tips/the-novak-djokovic-diet/">Đoković smatra</a> da je njegova ishrana odgovorna za brz oporavak nakon napornih mečeva, a najveći značaj daje odsustvu alergija koje su ga mučile od detinjstva. Zanimljivo je reći, da su Novakovi roditelji posedovali piceriju, a da je on alergičan na osnovne sastojke pice, testo, sir i paradajz. Spekulisao bih da su njegove alergije izazvane dužem izlaganju ovim supstancama.</p>
            <p>Promena ishrane je Đokovića transformisala iz igrača koji se oslanja na dobre udarce u kontri i naizgled iz nemogućih pozicija, u igrača koji je stabilan, ima konsistentnost energije i samouverenost da preuzme kontrolu nad igrom.</p>
            <h3>Ishrana Novaka Đokovića</h3>
            <p>Đoković veruje da je hrana informacija, a da je svaki obrok komunikacija sa sopstvenim telom.</p>
            <p>Ovakav pristup ishrani nazivao bih intuitivna ishana i za cilj ima da čoveka nauči da slu&scaron;a svoje telo i time se vodi pri odabiru namirnica i vemenu obroka. Međutim, iako možda zvuči jednostavno, intuitivan pristup ishrani je težak i zahtevan. Pored dovoljno vremena neophodnog za pripremu obroka, zahteva poznavanje svog tela i dosledan raspored obroka, treninga i odmora. Ovo je ishrana koju često predlažem strankama, ali se očekivano razlikuje za svakog pojedinca i zahteva trud, mene kao savetnika i stranke koja te savete treba da usvoji. Sa druge strane, kada se intuitivni režim jednom uspostavi, lako ga je održavati i postiže značajne zdravstvene i fizičke benefite, bez psihičkog napora kakav mogu predstavljati restriktivne dijete ili ekstremni režimi.</p>
            <p>Zbog specifičnih zahteva njegovog tela, <a href="https://www.joe.ie/fitness-health/nocak-djokovic-gives-the-low-down-on-the-diet-that-made-him-world-number-one-366598#:~:text=In%20simple%20terms%2C%20it%20looks,sources%20of%20protein%20and%20carbohydrates">Novak izbegava gluten i mlečne proizvode</a>, a &scaron;ećere unosi u malim količinama. On uglavnom jede povrće, mahunarke, belo meso, ribu, voće, ora&scaron;aste plodove, semenke, leblebije, sočivo i unosi dosta zdravih masti. Iako izbegava lo&scaron;e &scaron;ećere kao i neadekvatne proteine, Đoković nije na bez&scaron;ećernoj ili keto ishrani, već unosi &scaron;ećere iz dobrih izvora i u vreme kada su potrebni njegovom telu.</p>
            <p>Nakon pobede nad Federerom, 2019. godine <a href="https://thebeet.com/tennis-star-novak-djokovic-reveals-what-he-eats-in-a-day-its-plant-based/">izjavio je</a> da svoju ishranu ne bi nazvao veganskom, zbog pogre&scaron;nog tumačenja te reči. On kaže da je njegova ishrana zasnovana na biljkama i da je tako već mnogo godina. Dok je meso dobar izvor proteina, profesionalnom sportisti nikako ne odgovara dugo vreme varenja, kao ni previ&scaron;e životinjskih masti. Ishrana zasnovana na bilkama očito obezbeđuje dovoljno energije i proteina za izgradnju mi&scaron;ića svetskog prvaka.</p>
            <p>O svijim obrocima i navikama vezanim za ishranu prvak sveta je pisao u svojoj knjizi &sbquo;&sbquo;Serviraj do pobede&sbquo;&sbquo; izdatoj 2013. godine. Nastavak teksta govori o njegovim obrocima tokom tri dana koji su dati u toj knjizi.</p>
            <h3>&Scaron;ampionski doručak</h3>
            <p>Novak svaki dan počinje <strong>ča&scaron;om mlake vode</strong> čim se probudi. Jedna do dve ča&scaron;e vode ujutru, pomažu telu da se rehidrira nakon noći i moja su preporuka svima koji nemaju specifične probleme.</p>
            <p>Vodi usleđuju <strong>dve ka&scaron;ike meda</strong>. Prema Đokovićevim rečima na&scaron;em telu je potreban &scaron;ećer, a posebno fruktoza, koja se može naći u voću, nekom povrću i medu. Ovakvom praksom, Novak prekida stanje gladovanja u koje njegovo telo upada tokom noći, &scaron;to definitivno ima pozitivne efekte na ostatak njegovog dana. Moje mi&scaron;ljenje je da ukoliko niste na specifičnom režimu ishrane, unos ugljenih hidrata, uključujući i proste &scaron;ećere, treba obaviti ujutru i telu dati dovoljno vremena da ih iskoristi tokom dana. Dodao bih, da se fruktoza može naći i u većini prerađenih i zapakovanih namirnica, od slatki&scaron;a do kečapa, ali one nisu dobar izvor po&scaron;to pored fruktoze unosimo druge nezdrave stvari.</p>
            <p>Glavni deo doručka je najče&scaron;će <strong>musli</strong>. Osnova muslija su organske bezglutenske pahuljice ovsa, čemu Novak u zavisnosti od raspoloženja dodaje brusnice, suvo grožđe, seme bundeve ili suncokreta, badem, puter od indiskog oraha, banane ili neko drugo voće. Ovakav doručak bogat je ugljenim hidratima, a dodatno sadrži puno vitamina i elektrolita. Ovo je doručak koji bi preporučio gotovo svima i veoma je sličan onome &scaron;to ja uglavnom jedem ujutru.</p>
            <p>Recept za <a href="https://www.mensjournal.com/food-drink/foods-turned-djokovic-no-1-player-game/">Novakov musli</a>:</p>
            <ul>
                <li>100 grama pahuljica ovsa bez glutena</li>
                <li>50 grama su&scaron;enih brusnica</li>
                <li>50 grama suvog grožđa</li>
                <li>50 grama semenki bundeve ili suncokretovog semena</li>
                <li>50 grama seckanih badema</li>
            </ul>
            <p>Doručak se priprema vrlo prosto. Svi sastojci se pome&scaron;aju u činiji i preliju mlekom. Đoković koristi pirinčano mleko ili mleko badema, a dodaje i sveže voće poput banana, bobica ili jabuke kao zaslađivač.</p>
            <h3>Prepodnevna užina</h3>
            <p>U zavisnosti od toga kako se oseća, Novak ponekad ima užinu između ručka i doručka. Ova užina nikad nije previ&scaron;e obilata i uglavnom se sastoji od složenih ugljenih hidrata, malo zdravih masti i proteina. Za fizički veoma aktivnog čoveka ovaj obrok je ponekad neophodan, kako bi imao dovoljno energije za trening.</p>
            <p><strong>Hleb, tost ili krekeri bez glutena </strong>su osnova užine. Na žalost, nisam siguran koje bra&scaron;no koristi niti kako je pripremljeno tako da je nutritivna procena samo spekulacija. Hleb i krekeri su bogati ugljenim hidratima, imaju malo masti i malo proteina. Dodatno, sadrže vitamin B3 i kalcijum, ali i druge vitamine i minerale ukoliko se koriste integralna bra&scaron;na. Ovakvu užinu Vam ne bih preporučio, osim ako ste fizički izuzetno aktivni pa ste do užine &sbquo;&sbquo;potro&scaron;ili&sbquo;&sbquo; energiju unetu tokom doručka. Za prosečne ljude bi jedna voćka ili malo ora&scaron;astih plodova trebala da bude dovoljna do ručka.</p>
            <p>Izvor dobrih masti, tokom ovog obroka, Đokoviću je uglavnom <strong>avokado</strong>. Avokado je jedno od voća sa najvi&scaron;e masti, od kojih su većina monosaturisane ili zdrave masti, one se mogu naći i u maslinovom ulju. Ove masti povezane su sa zdravljem srca, imaju pozitivne efekte na kontrolu težine i ublažavaju upalne procese. U odnosu na drugo voće avokado sadrži malo ugljenih hidrata, od kojih su većina dijetalna vlakna.</p>
            <p>Kako bi održao mi&scaron;iće i postigao bolji oporavak nakon treninga, Novaku su neophodni i proteini. Glavni izvor proteina tokom užine je <strong>tunjevina</strong>. Ova riba sadrži vi&scaron;e proteina od pilećeg belog mesa koje se najče&scaron;će predstavlja kao najbolji izvor proteina u ishrani. Tunjevina je dodatno nisko kalorična, ima puno magnezijuma i vitamina B6 i B12 koji su najproblematičniji ljudima na biljnoj ishrani.</p>
            <h3>Ručak prvaka</h3>
            <p>Đokovićev ručak je takođe fokusiran na kompleksne ugljene hidrate, kako bi imao dovoljno energije za dan napornog treninga. Prema njegovim rečima, tokom dana želi da svome telu kaže da mu treba energija, a ovo postiže unosom ugljenih hidrata sa malo proteina.</p>
            <p><strong>Testenina bez glutena sa grilovanim povrćem</strong> je Novakov normalan ručak. Ova testenina pravi se od pirinčanog bra&scaron;na i dodaju joj se tikvice, asparigus, su&scaron;eni paradajz i veganski sir. Đoković je jede sa me&scaron;anom zelenom salatom (spanać, zelena salata, ajzberg itd.), a ukoliko igra meč, ovom ručku dodaje meso ili ribu. Ovakav ručak je zdrav, obezbećuje energiju i nutrijente neophodne na&scaron;em telu. Rado bih ga preporučio većem delu populacije, ali sa zamenom testenine od pirinča za integralni pirinač.</p>
            <p><strong>Začinjena salata od heljde </strong>se priprema od heljdinih nudli (engl. <em>soba noodles</em>) koje su tradicionalno Japansko jelo ili prilog. Sadrže vi&scaron;e proteina i vlakana od ostalih nudli, ali su očekivano bogate složenim ugljenim hidratima. Nudlama se dodaje crvena paprika, rukola, bosiljak, indijski orah i dresing. Ovaj obrok, Novak takođe jede sa me&scaron;anom zelenom salatom. Nutritivno, sličan je prethodnom, tako da moje mi&scaron;ljenje ostaje nepromenjeno. Ljudi koji nisu intolerantni na gluten mogu da konzumiraju drugu testeninu, po&scaron;to je po mom mi&scaron;ljenju, testenina od heljde pomalo bezukusna.</p>
            <p>Recept za <a href="https://www.mensjournal.com/food-drink/foods-turned-djokovic-no-1-player-game/">Novakovu pastu</a>:</p>
            <ul>
                <li>2 ka&scaron;ike maslinovog ulja, najbolje hladno ceđenog devičanskog</li>
                <li>2 sitno iseckana čenja belog luka</li>
                <li>2 tikvice, prepolovljene po dužini i tanko isečene na polumesece</li>
                <li>Oko 200 grama asparigusa koji je isečen na kolutiće</li>
                <li>4 &scaron;olje testenine od pirinča, oko 700 grama</li>
                <li>Oko 50 grama su&scaron;enog paradajza</li>
                <li>Četvrtina ka&scaron;ičice soli, slobodno dodajte vi&scaron;e po ukusu</li>
                <li>2 ka&scaron;ike rendanog veganskog sira, po ukusu</li>
                <li>Sveži začini, na primer bosiljak ili per&scaron;un, po ukusu</li>
            </ul>
            <p>U velikom tiganju na maslinovom ulju propržite beli luk, oko 5 minuta. Dodajte iseckane tikvice i asparigus i uz povremeno me&scaron;anje pržite oko 8 minuta. U međuvremenu skuvajte testeninu prema uputstvima sa pakovanja i ocedite je. Kada povrće omek&scaron;a pome&scaron;ajte ga sa testeninom i dodajte so, su&scaron;eni paradajz, začine i sir. Ova količina dovoljna je za 4 osobe.</p>
            <p>Dodao bih da iako recept predlaže prženje na maslinovom ulju, ovo treba izbegavati. Prženje na masti ili suncokretovom ulju je zrdavije i treba izbegavati zagrevanje maslinovog ulja. Moji predlog bi bio da beli luk izpržite na suncokretovom ulji ili masti, a da tikvice i asparigus dinstate kako bi zadržali vi&scaron;e ukusa i nutrijenata. Maslinovo ulje je ukusno i bogato dobrim mastima, pa ga možete dodati nakon prženja, tokom me&scaron;anja testenine i povrća.</p>
            <h3>Poslepodnevna užina</h3>
            <p>Za razliku od prepodnevne užine koju Novak ima samo kada oseća potrebu, poslepodnevna je deo njegove rutine. Ovaj obrok je izuzetno lagan i uglavnom se sastoji od nekog voća, poput jabuke, banane, avokada, dinje, itd., putera od indijskog oraha, krekera bez glutena ili &scaron;tanglice napravljene od ora&scaron;astih plodova.</p>
            <p>Ovakva užina će prijati gotovo svima. Naravno, ukoliko va&scaron;e životne navike ne podrazimevaju užinu, nema potrebe da je dodajete. Tri obroka u toku dana su dovoljna i prijaju većini ljudi. Sa druge strane, ukoliko osećate glad pre većere, razmislite o dodavanju užine kako bi postigli bolju kontrolu gladi i izbegli prejedanje u toku večere.</p>
            <p>Zapamtite da su ora&scaron;asti plodovi bogati energijom tako da morate biti oprezni sa količinom koju unosite, posebno ukoliko poku&scaron;avate da smanjite telesnu težinu. Takođe, Novaku je ovo 4. obrok u toku danatokom kojeg unosi ugljene hidrate, &scaron;to je neophodno nekome sa njegovim intenzitetom fizičke aktivnosti, ali prosečno aktivnim ljudima može biti previ&scaron;e.</p>
            <h3>Večera svetskog broja jedan</h3>
            <p>Prema Novakovim rečima noću mu ne treba energija, pa za večeru svome telu &scaron;alje poruku da počisti nered koji je u toku dana napravio. Njegova večera sadrži puno proteina i sastoji se od zdrave salate, ribe ili piletine i supe od povrća. Ovakv obrok ne samo da mu obezbeđuje dobru regeneraciju mi&scaron;ića i oporavak nakon takmičenja ili treninga, već i bolji san i odmor kako bi sutra mogao da ponovi svoji naporan dan.</p>
            <p><strong>Cezar salata </strong>koja se pravi od kelja, komorača, kinoe i pinjola sa dresingom, jedna je od novakovih opcija za poslednji obrok u toku dana. Kelj sadrži puno dijetalnih vlakna i kalcijuma, dok kinoa sadrži malo vi&scaron;e proteina i složene ugljene hidrate. Komorač i pinjola upotpunjuju ovaj obrok vitaminima i mineralima, na primer vitamin C i K, gvožđe i magnezijum.</p>
            <p>Ova salata veoma je zdrava, ali je relativno siroma&scaron;ne proteinima, pa je Novak jede sa <strong>filetima lososa</strong>, pečenim paradajzom i marinadom. Losos sadrži oko 20 grama proteina i oko 200 kalorija na 100 grama, &scaron;to ga čini odličnim izvorom dobrih proteina proteina, ali je kaloričniji od pilećeg belog mesa.</p>
            <p><strong>Salata od tunjevine</strong> je jo&scaron; jedna opcija za večeru koju Đoković spominje u svojoj knjizi. Pored tunjevine u njoj se nalaze boranija, beli pasulj, rukola, crvena paprika, paradajz i leblebije. Tunjevina je jo&scaron; bogatija proteinima, a ima nižu kalorijsku vrednost od lososa. U 100 grama tunjevine nalazi se oko 28 grama proteina i 132 kalorije. Povrće u ovoj salati sadrži veliki broj vitamina i minerala koji su nam neophodni za dobro zdravlje i aktivan život. Kao prilog uz ovu salatu novak jede <strong>pečeni paradajz</strong>.</p>
            <p><strong>Sveža salata</strong> se pravi od me&scaron;anog zelenog povrća sa avokadom i domaćim dresingom. Umerena je sa kalorijama, ali i proteininima, a bogata vlaknima i vitaminima. Novak je jede sa <strong>pečenom piletinom,</strong> kojoj ne skida kožicu, prelivenom limunovim sokom. Ovako je njegova večera nutritivno upotpunjena i obezbeđuje mu neophodne proteine i energiju.</p>
            <p><strong>Supa </strong>je obavezan deo večere najboljeg svetskog tenisera. Tri supe koje se mogu naći u njegovoj knjizi su: <strong>Supa od paradajza</strong>, <strong>Supa od &scaron;argarepe</strong><strong>i đumbira</strong> i <strong>Minestrone supa</strong> koja se pravi od &scaron;argarepe, crnog luka, pasulja, paradajza i pirinča ili testenine. Supe su većinski voda tako da pomažu u dobroj hidraciji i adekvatnom unosu elektrolita. Elektroliti su uključeni u brojne procese u telu uključujući grčenje mi&scaron;ića, regulaciju pH vrednosti i znojenje, tako da je njihov dobar unos od velikog značaja za nekoga ko je fizički aktivan. Supe su zdrave i korisne i za ostatk populacije, a ne morate ih jesti samo uveče. Supa u vreme ručka je sasvim u redu.</p>
            <p>Recept za Novakovu supu od <a href="https://www.mensjournal.com/food-drink/foods-turned-djokovic-no-1-player-game/">&scaron;argarepe i đumbira</a>:</p>
            <ul>
                <li>1 sitno iseckan crni luk</li>
                <li>900 grama olju&scaron;tene i krupno isečene &scaron;argarepe</li>
                <li>4 čenja sitno iseckanog belog luka</li>
                <li>1 lovorov list</li>
                <li>2 ka&scaron;ike sveže narendanog đumbira</li>
                <li>7 do 8 &scaron;olja buljona napravljenog od povrća</li>
                <li>Četvrtina ka&scaron;ičice muskatnog ora&scaron;čića</li>
                <li>Četvrtina ka&scaron;ičice soli, po ukusu</li>
            </ul>
            <p>U velikoj &scaron;erpi, na umerenoj temperaturi, izdinstati crni luk oko 8 minuta. Dodati &scaron;argarepu, beli luk, lovorov list, đumbir i dinstati jo&scaron; 20 minuta. Dodati buljon i ostaviti da se krčka sve dok &scaron;argarepa i luk ne ome&scaron;aju, oko jo&scaron; 20 minuta. Izvaditi lovorov list i supu prebaciti u blender, izblendirajte je tako da dobijete jednoličnu ka&scaron;u. Dobijenu pasiranu supu začinite muskatnim ora&scaron;čićem i solju, a možete dodati i malo đumbira ili rendanu koru pomorandže.</p>
            <h3><strong>Zavr&scaron;ne reči</strong></h3>
            <p>Novakova ishrana je stvarno fantastična i bez nje te&scaron;ko da bi postizao rezultate kakve postiže. Naravno, pored ishrane izuzetno je bitna i njegova disciplina i rigorozni treninzi. Interesantno je da Đoković svoju hranu sam priprema, a za vreme obroka izbegava da radi bilo &scaron;ta drugo, poput čitanja ili gledanja u telefon ili televizor, već se u potpunosti posvećuje hrani.</p>
            <p>Vodeći se Novakovim primerom ljudi su popularizovali ishranu bez glutena, &scaron;to je potpuno sumanuto uzimajući u obzir da većina ljudi nema njegove, već neke svoje, potpuno drugačije probleme. Biohemijske laboratorije omogućavaju Vam da testirate imate li intoleranciju na neki alergen u hrani, &scaron;to Vam ja iskreno preporučujem.</p>
            <p>Đokovićeva ishrana je savr&scaron;ena za njega, ali uspostavljanje dobrog odnosa sa hranom i pravljenje Va&scaron;e savr&scaron;ene ishrane je veoma ličan proces i nemoguće ga je samo prepisati. Intuitivni pristup ishrani je vremenski verovatno najzahtevniji od svih režima ishrane, ali Vam omogućava dobru komunikaciju sa svojim telom i razumevanje njegovih potreba. Ukoliko se odlučite da promenite svoju ishranu, nudim Vam pomoć u odabiru savr&scaron;ene ishrane za Vas i Va&scaron; stil života, ali i savete vezane za motivaciju i istrajnost.</p>
            <p>Druge interesantne tekstove možete naći na mom <a href="/science/">BLOG-u</a>, a za sva pitanja, slugestije ili kritike možete mi se obratiti putem dru&scaron;tvenih mreža.&nbsp;</p>
        </div>
    )
}

export default BlogPost0