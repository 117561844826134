import React from 'react'
import FirstImage from '../blog_images/blog_post_large_keto.jpg'

const BlogPost4 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere'}}>
            <div>
                <img src= {FirstImage} alt="Description" className="blogimage"/>
            </div>
<p>Keto dijeta postala je veoma popularna poslednjih godina. O njoj postoji puno radova, članaka i tekstova, ali i dosta neslaganja među lekarima, dijetičarima, sportistima, kao i među nestručnom populacijom. Dok je jedni smatraju magičnim lekom i dobrim načinom za gubitak neželjenih kilograma, drugi je nalaze opasnom po zdravlje, imunitet i psihičke sposobnosti.</p>
<p>Ukoliko ste do&scaron;li po brzi odgovor, bojim se da ga nema. Radovi ukazuju na to da je keto dijeta veoma efikasna ukoliko vam je jedini cilj brzi gubitak masti u telu, ali upozoravaju na nedovoljno proučene dugoročne efekte na zdravlje, kao i na opasnost od &bdquo;jo-jo&rdquo; efekta. Dodatno, nagla&scaron;avaju važnost kontrolisane tranzicije sa ketogene ishrane na normalnu i važnost konstantnog motrenja od strane lekara ili dijetičara. Postoje velike nade u mogućnost kori&scaron;ćenja ovog ekstremnog pristupa ishrani u lečenju i prevenciji različitih, pre svega psihičkih, bolesti poput epilepsije kod dece. S druge strane, pri dugim bolestima i poremećajima, poput dijabetesa ili poremećenog rada jetre, može dovesti do komplikacija i kontraefekta.</p>
<h3>Nekoliko obja&scaron;njenja pre početka</h3>
<p>Pre obja&scaron;njenja keto dijete neophodno je razumeti par procesa u na&scaron;em telu koji se tiču metabolizma i energetskih potreba na&scaron;eg tela, pre svega metabolizam ugnjenih hidrata.</p>
<p>Ugljeni hidrati nalaze se u većini namirnica koje ljudi &scaron;irom sveta svakodnevno konzumiraju, poput hleba, pirinča, mleka, testenine, krompira, pasulja i drugog voća i povrća. Predstavljaju osnovni izvor energije za funkcionisanje ljudskog organizma i jedina su hrana koju prihvataju mozak i mi&scaron;ići. Ljudi u telu poseduju veoma ograničene zalihe ugljenih hidrata u vidu glikogena koji se skladi&scaron;ti u jetri i iz nje uzima kada je to potrebno. Dodatno, telo je u stanju da poizvodi ugljene hidrate, ali u nedovoljnim količinama za zadovoljavanje potreba tela.</p>
<p>Pri gladovanju rezerve bivaju brzo potro&scaron;ene i telo prelazi na drugi izvor energije, masti. Količina masti koju možemo da skladi&scaron;timo kao i njena energetska vrednost mnogo je veća od količine i vrednosti ugljenih hidrata, zbog čega se masti smatraju dugoročnim skladi&scaron;tem energije.</p>
<p>Međutim, mozak ne može da se hrani mastima, a pri produženom gladovanju telo ne proizvodi dovoljne količine ugljenih hidrata za zadovoljenje njegove potrebe, zbog čega dolazi do produkcije ketonskih tela. Ketonska tela su molekuli proizvedeni u jetri od uskladi&scaron;tenih masnih rezervi, koje se pri ovome ubrzano tro&scaron;e. Kada organizam počne da proizvodi ketonska tela ona zamenjuju ugljene hidrate kao izvor energije i kaže se da se telo nalazi u stanju ketoze.</p>
<h3>&Scaron;ta je keto dijeta?</h3>
<p>Keto ili ketogena dijeta podrazumeva ishranu koja izaziva proizvodnju ketonskih tela u organizmu. Nedovoljan unos ugljenih hidrata, manji od 50g dnevno, &scaron;to je manje od količine koja se nalazi u jednom većem krompiru, tokom 3 do 4 dana u telu izaziva sličnu reakciju kao prilikom gladovanja. Dolazi do produkcije ketonskih tela i ubrzanog tro&scaron;enja masti kako bi se zadovoljile specifične potrebe tela za energijom. Pod keto dijetom se podrazumeva bilo koja ishrana koja uključuje unos dovoljno male količine ugljenih hidrata kako bi se postiglo stanje ketoze. Keto dijete uglavnom uključuju unos velikih količina masti, umerenih količina proteina i male količine ugljenih hidrata.</p>
<h3>Vrste keto dijeta</h3>
<p>Usled razlika u kulturi, religiji i finansijskim mogućnostima, &scaron;irom sveta se tokom godina <a href="https://pubmed.ncbi.nlm.nih.gov/17332207/">razvilo</a> vi&scaron;e planova ishrane koji izazivaju stvaranje ketonskih tela. Razlike u ovim planovima uključuju različite odnose ugljenih hidrata, masti i proteina, restrikcije tečnosti, odnose namirnica u ishrani i količine dozvoljenog unosa kalorija.</p>
<p>Neki od <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6251269/">značajnih su</a>:</p>
<p><strong>Standardna ketogena dijeta</strong> &ndash; dijeta sa veoma niskim unosom ugljenih hidrata, manje od 10% dnevnih energetskih potreba. Zarad poređenja, zvanične preporuke <a href="https://www.dietaryguidelines.gov/">ishrane za Amerikance</a> savetuju unos od 45 do 65% dnevnih energetskih potreba iz ugljenih hidrata. Pored ovoga, standardna keto dijeta uključuje dobijanje vi&scaron;e od 70% energije iz masti i oko 20% iz proteina. U pro&scaron;losti je uključivala post kao vid pripreme, smanjen unos kalorija i tečnosti, ali se danas u većini centara?? ove mere ne po&scaron;tuju.</p>
<p><strong>Ciklična ketogena dijeta</strong> <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7551961/#B8-nutrients-12-02832">uključuje</a> period niskog unosa ugljenih hidrata, ispod 15%, koje prate periodi visokog unosa ugljenih hidrata, do 70%. Ideja je postizanje stanje ketoze u kraćem vremenskom periodu kome sledi period oporavka mi&scaron;ićnog glikogena i hormona. Na primer, 5 dana sa niskim unosom ugljenih hidrata, kome slede 2 dana visokog unosa, tako da jedna nedelja čini jedan ciklus. Potrebno je naglasiti, da tokom dana kada je dozvoljen unos visok unos ugljenih hidrata i dalje treba biti umeren sa unosom kalorija, posebno ukoliko je cilj dijete mr&scaron;avljenje.</p>
<p><strong>Ciljana ili targetirana ketogena dijeta</strong> &ndash; je specifično predviđena za ljude sa intenzivnom fizičkom aktivno&scaron;ću tokom treninga. Veoma je slična standardnoj ketogenoj dijeti, ali dozvoljava unos ugljenih hidrata oko treninga, uglavnom pre, kako bi bili potro&scaron;eni u toku treninga.</p>
<p><strong>Visoko proteinska ketogena dijeta/strong</strong> &ndash; predviđena je za ljude koji žele da smanje procenat masti, a povećaju ili održe procenat mi&scaron;ića u telu. Slična je standardnoj ketogenoj dijeti, ali proteini treba da zadovolje oko 35% energetskih potreba. Procenat ugljenih hidrata treba da ostane između 5 i 10%, a ostatak potreba se zadovoljava iz masti.</p>
<p>U narednom tekstu biće vi&scaron;e reči o pozitivnim, a zatim i o negativnim efektima keto dijete na zdravlje. Ukoliko smatrate da je keto dijeta pogodna za Vas, savetujem Vam da se konsultujete sa mnom ili drugom stručnom osobom kojoj verujete, kako bi ste smanjili i izbegli nepotrebne rizike, ali i kako bi ste odabrali najpogodniji režim ishrane za sebe. Keto dijeta predstavlja ekstreman način ishrane i nikako se ne sme shvatati olako.</p>
<h3>Pozitivni efekti keto dijete</h3>
<p><strong>Gojaznost</strong></p>
<p><a href="https://jissn.biomedcentral.com/articles/10.1186/s12970-020-00348-7">Istraživanje</a> objavljeno 2020. godine proučavalo je uticaj keto dijete na sastav tela i snagu kod žena. Zaključeno je da keto dijeta pomaže u smanjenu masti u telu i održanju mi&scaron;ićne mase kada je kombinovana sa treninzima izdržljivosti. Međutim, pokazala se kao neefikasana za povećanje mi&scaron;ićne mase.</p>
<p><a href="https://www.ncbi.nlm.nih.gov/books/NBK499830/">Sistemskim pregledom</a> vi&scaron;e istraživanja ispitivani su efekti dijeta sa niskim unosom masti i niskim unosom &scaron;ećerau periodu dužem od godinu dana. Pokazano je da su dijetske intervencije sa niskim unosom ugljenih hidrata, poput keto dijete, imale značajno bolje rezultate na gubitak telesne težine od onih sa niskim unosom masti. Ljudi koji su se pridržavali keto dijete gubili su do 4,5 kg u toku prve 2 nedelje. Treba naglasiti da keto dijeta ima diuretički efekat, pa ovako veliki početni gubitak može biti posledica gubitka tečnosti.</p>
<p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3945587/">Studija iz 2014.</a>Studija iz 2014. zaključila je da ketogena dijeta može pomoći u kontroli gladi i unapređenju oksidativnih mehanizama, pa samim tim i smanjenju telesne težine. U zaključku obave&scaron;tavaju da ukoliko je ispravno kori&scaron;ćena ketogena ishrana može predstavljati korisno oruđe za re&scaron;avanje gojaznosti u rukama stručnjaka.</p>
<p><strong>&nbsp;&Scaron;ećeri</strong></p>
<p>Jedan od indirektnih pozitivnih efekata keto dijete je smanjeni unos rafinisanih &scaron;ećera. Veliki potencijal rafinisanih ugljenih hidrata, poput onih u slatki&scaron;ima i belom bra&scaron;nu, da izazovu negativne efekte po na&scaron;e zdravlje dugo je bio zanemarivan. Međutim danas <a href="https://www.ncbi.nlm.nih.gov/books/NBK499830/">znamo</a> da je povećana konzumacija rafinisanih &scaron;ećera povezana sa povećanem sklonosti ka gojenju i metaboličkom sindromu za 44%, kao i povećanju rizika od razvoja dijabetesa za 26%.</p>
<p><strong>Epilepsija</strong></p>
<p>Uticaj ketogene dijete na ishranu <a href="https://pubmed.ncbi.nlm.nih.gov/17332207/">poznat</a> je već 100 godina. Ovo predstavlja verovatno najznačajniju primenu ove dijete u medicini. Postoji mnogo podataka i mnogo radova koji ukazuju na efikasnost sa velikom konzistencijom. 10 do 15% dece kojima se uvede keto ishrana, godinu dana kasnije nemaju napade, 30% ima vi&scaron;e od 90% smanjenu učestalost napada, a 40 do 50% nalaze dijetu neefikasnom ili previ&scaron;e te&scaron;kom i odustaje tokom prvih 6 meseci. </p>
<p><strong>Krvni parametri</strong></p>
<p><a href="https://www.ncbi.nlm.nih.gov/books/NBK138038/">Grupa ispitanika</a> kojima je bila prepisana dijeta sa veoma niskim unosom ugljenih hidrata, postigla je bolje dugoročne rezultate smanjenja telesne težine, triglicerida i donjeg pritiska ali i veće povećanje LDL i HDL holesterola od grupe kojoj je bila prepisana standardna dijeta sa niskim unosom masti. </p>
<p><strong>Dijabetes 2</strong></p>
<p>Praćenje dijete koja podrazumeva veoma nizak unos ugljenih hidrata <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7480775/">dovodi do</a> smanjenja glikemije količine ugljenih hidrata u krvotoku, smanjenja nivoa insulina i smanjenja insulinske rezistencije, &scaron;to kod pacijenata sa dijabetesom može dovesti do smanjene potrebe za lekovima. Potrebno je naglasiti da stručnjaci jo&scaron; uvek raspravljaju o krajnim efektima ove dijete na pacijente sa dijbetesom.</p>
<h3>Negativni efekti keto dijete</h3>
<p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6371871/">U kanadskoj publikaciji iz 2018</a> naučnici su se bavili proučavanjem velikog broja radova sa temom ketogenih dijeta. Zaključili su da je javljanje nuspojava često i da uključuje: zatvor (33%), lo&scaron; zadah (30%), grčeve u mi&scaron;ićima (28%), glavobolju, dijareju, slabost, osip.</p>
<p>Zbog gore pomenutih nuspojava, tokom svih istraživanja ketogenih dijeta postoji mali broj ispitanika i veliki broj ljudi koji odustaju tokom ispitivanja, &scaron;to u mnogome smanjuje validnost svakog istraživanja. Dodatno, istraživanja se iz bezbednosnih razloga ne sprovode duže od 2 godine, pa se postavlja pitanje &scaron;ta se de&scaron;ava nakon ovog perioda.</p>
<p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7480775/">Poslednja istraživanja</a> ukazuju se ovaj tip ishrane ne može smatrati održivim i da mora biti prekinut nakon nekoliko meseci do dve godine. Ovo ostavlja mogućnost da i pored uspe&scaron;nosti dijete kada je gubljenje telesne težine u pitanju, dugoročno ne dolazi do postizanja željenih efekata i da pacijenti brzo vrate kilažu po prestanku dijete. Nezavisno od trajanja dijete, prelazak sa ketogene na normalnu ishranu mora biti praćen, kontrolisan i postepen kako ne bi do&scaron;lo do nazadovanja.</p>
<p>Jedan od značajnih problema svih ishrana sa niskim unosom ugljenih hidrata je nedovoljan unos voća povrća i žitarica. Ovo <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6251269/">može dovesti</a> do različitih nedostataka vitamina i minerala neophodnih za pravilno funkcionisanje organizma. Ispravna suplementacija može ublažiti ove negativne efekte.</p>
<p>Priprema mesa može biti jo&scaron; jedan od <a href="https://pubmed.ncbi.nlm.nih.gov/17452738/">problema</a>. Kada se meso priprema na povi&scaron;enim temperaturama, poput prženja i pripeme na ro&scaron;tilju, dolazi do stvaranja produkata koji kada su uneti kroz ishranu povećavaju upalu i oksidativni stres unutar organizma i vode ubrzanom starenju i povećanju sklonosti ka bolestima koje su povezane sa staro&scaron;ću.</p>
<p>Postoje radovi koji ukazuju da se pozitivni efekti keto dijete na bolesti poput epilepsije, mogu postići i drugim, raznovrsnijim i održivijim dijetama, poput izmenjene <a href="https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD001903.pub5/epdf/abstract">Atkinsove dijete</a>.</p>
<h3>Zaključak</h3>
<p>Za kraj, želeo bih da zaključim da je umerenost i oprez ključ svake dijete koja će se praktikovati duži vremenski period. Dok ketogene dijete očito pomažu pri kratkoročnom postizanju ciljeva i pobolj&scaron;anja specifičnih stanja, mogu dugoročno dovesti do problema sa zdravljem i povećane gojaznosti. Takođe, javljaju se brojne tegobe zbog kojih ljudi često prekidaju ovu dijetu. Umesto naglih promena i uvođenja ekstremnih stilova ishrane preporučujem Vam balansiranje nutijenata tako da sve namirnice budu na tanjiru i savetovanje sa stručnim licima pre dono&scaron;enja velikih odluka o promenama svojih životnih navika. <strong>Raznovrsna, umerena ishrana je uvek bila i ostaće najzdraviji način ishrane.</strong></p>
<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost4
