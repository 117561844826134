import React from 'react'
import OfficePhoto from '../Images/office.jpg'
import CallToActionIcon from '../Images/call_to_action.png'
import { useNavigate } from 'react-router-dom';

const Office = () => {
        return (
            <div style={{ height: '100VH', scrollSnapAlign: 'start' }} >
                <div style={{ display: 'flex', flexDirection: 'row', height: '100VH', flexWrap: 'wrap' }} >
                    <div style={{ width: '30VW', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: '1', marginRight: '20px', marginLeft: '20px', minWidth: '240px' }}>
                        <h2 style={{ color: '#c8cac0', fontSize: '3rem', padding: '5px', textAlign: 'center' }} className="main">Zakaži svoj prvi sastanak!</h2>
                            <ButtonMailto  label="Write me an E-Mail" mailto="mailto:dusan.aragonski@gmail.com"></ButtonMailto>
                    </div>
                    <div style={{ display: 'flex', flexGrow: '3', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={OfficePhoto} className='officePic' style={{ maxWidth: '65vW', height:'auto', objectFit: 'contain', borderRadius: '25px' }} alt='' />
                    </div>
                </div>
            </div>
        )
    }

const ButtonMailto = () => {
    const navigate = useNavigate();
    return (
            <img onClick={() => navigate('/contact')} src={CallToActionIcon} alt='' style={{ width: '80px', borderRadius: '100%', padding: '8px',cursor: 'pointer' }} />
    );
};



export default Office